@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';








@import '@/assets/scss/_colors.scss';
.RegistrationEmailVerification_root {
  h2 {
    font-size: 34px;
    line-height: 144%;
    color: $primaryText;
  }
  p {
    margin: 20px 0 0 0;
    color: $primaryText;
    font-size: 14px;
    line-height: 16px;
  }
}
